<template>
  <div class="box">
    <h1>思路，在此打开</h1>
    <div class="form-wrapper">
      <user-login></user-login>
    </div>
  </div>
</template>
<script>
export default {
  name: "Login",
  data() {
    return {};
  },
  components: {
    UserLogin: () => import("../../components/user/UserLogin.vue"),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.box {
  max-width: 980px;
  margin: 0 auto;
  h1 {
    font-size: 36px;
    color: #fff;
    line-height: 1.1;
    padding-top: 36px;
    max-width: 1000px;
    min-width: 380px;
    letter-spacing: 0;
    font-weight: 600;
  }
  h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.1;
    margin: 5px 0 40px;
    color: #454545;
  }
  .form-wrapper {
    height: 480px;
    margin: 72px auto 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .form-item {
    margin-bottom: 30px;
  }

  .form-seperator {
    width: 1px;
    background: #c4c4c4;
    margin: 0 19px;
    max-height: 300px;
  }
}
</style>
